import { FormControl } from '@mui/material';
import styled from 'styled-components';

export const SimilarGenesGridWrapper = styled.div`
    height: 400px;
    margin-top: 10px;
`;

export const QueryGeneSelectFormControl = styled(FormControl)`
    min-width: 200px;
    margin-right: 10px;
`;

export const DistanceMeasureFormControl = styled(FormControl)`
    min-width: 200px;
`;
