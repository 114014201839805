import styled from 'styled-components';

export const GOEnrichmentGridContainer = styled.div`
    flex-grow: 1;
    overflow: hidden;
`;

export const GOEnrichmentContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
`;

export const GOEnrichmentControls = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 10px;
`;

export const GOEnrichmentControl = styled.div`
    margin-right: 10px;
`;
