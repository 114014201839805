import styled from 'styled-components';

export const TitleSection = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
`;

export const Title = styled.h3`
    margin: 0;
`;
