import styled from 'styled-components';

export const CellContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
`;

export const CellLogo = styled.img`
    height: 2.5ex;
    vertical-align: sub;
    margin-left: 10px;
`;
