import styled from 'styled-components';

export const AssociationsGridWrapper = styled.div`
    height: 400px;
    margin-top: 20px;
`;

export const TermInfo = styled.h2`
    margin: 0 0 10px 0;
`;

export const TermName = styled.span`
    text-transform: capitalize;
`;

export const AmigoLink = styled.a`
    margin-left: 1ex;
`;

export const AmigoLinkImage = styled.img`
    height: 20px;
    position: relative;
    left: 10px;
    top: 3px;
`;
