enum SectionNames {
    FEATURES = 'features',
    SCREENSHOTS = 'screenshots',
    CITING = 'citing',
    REFERENCES = 'references',
    CREDITS = 'credits',
    CONTACT = 'contact',
}

export default SectionNames;
