export const GEN_CYAN = {
    50: '#E0F7FA',
    100: '#B2EBF2',
    200: '#80DEEA',
    300: '#4DD0E1',
    400: '#26C6DA',
    500: '#00BCD4',
    600: '#00ACC1',
    700: '#0097A7',
    800: '#00838F',
    900: '#006064',
    A100: '#84FFFF',
    A200: '#18FFFF',
    A400: '#00E5FF',
    A700: '#00B8D4',
    contrastDefaultColor: 'light',
    contrastDarkColors: '50 100 200 300 400 A100 A200',
    contrastLightColors: '500 600 700 800 900 A200 A400 A700',
};

export const GEN_GREY = {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    350: '#CECECE', // Extra
    400: '#BDBDBD',
    450: '#AEAEAE', // Extra
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#FFFFFF',
    A200: '#000000',
    A400: '#303030',
    A700: '#616161',
    contrastDefaultColor: 'dark',
    contrastDarkColors: '50 100 200 300 400',
    contrastLightColors: '500 600 700 800 900 A200 A400 A700',
};

export const GEN_ORANGE = {
    50: '#FBE9E7',
    100: '#FFCCBC',
    200: '#FFAB91',
    300: '#FF8A65',
    400: '#FF7043',
    500: '#FF5722',
    600: '#F4511E',
    700: '#E64A19',
    800: '#D84315',
    900: '#BF360C',
    A100: '#FF9E80',
    A200: '#FF6E40',
    A400: '#FF3D00',
    A700: '#DD2C00',
    contrastDefaultColor: 'light',
    contrastDarkColors: '50 100 200 300 400 A100 A200',
    contrastLightColors: '500 600 700 800 900 A200 A400 A700',
};
