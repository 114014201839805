import styled from 'styled-components';

export const TimeSeriesAndGeneSelectorContainer = styled.div`
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
`;

export const TimeSeriesSelectorWrapper = styled.div`
    flex-grow: 1;
`;
